
import axios, { AxiosError } from 'axios';
import { defineComponent, ref, onMounted, watch } from 'vue';
import { useRouter } from "vue-router";
import useAlert from "@/composables/Alert";
import {
  CampHeader,
  CampEmptyListFeedbackV2,
  CampTable,
  CampTableTd,
  CampTableTh
} from '@/components';
import { useLoaderStore } from "@/store/LoaderStore";
import CampModalDelRegister from '@/components/CampModalDelRegister.vue';
import { useCompanyStore } from '@/store/CompanyStore';
import { useFilterMainStore } from '@/store/FilterMainStore';
import modalProfileRelation from './Components/modalProfileRelation.vue';
import { Modal } from "bootstrap";



export interface IStoreProfileData {
  id?: number,
  name: string,
  points_value: number | string
}

interface IStoreProfileResponse {
  data: {
    error: boolean,
    message: string,
    data: IStoreProfileData[]
    errorCode: string
  }
}

export default defineComponent({
  name: "StoreProfilesList",
  components: {
    CampHeader,
    CampEmptyListFeedbackV2,
    CampTable,
    CampTableTd,
    CampTableTh,
    CampModalDelRegister,
    modalProfileRelation
  },
  setup() {
    const router = useRouter();
    const { showTimeAlert } = useAlert()
    const queryObject = ref<{
      isLoading: boolean,
      noError: boolean,
      data:  null | IStoreProfileData[]
    }>({
      isLoading: false,
      noError: false,
      data: null
    })
    const loaderStore = useLoaderStore()
    const itemDel = ref<IStoreProfileData | null>(null)
    const toggleModalDelRegister = ref(false)
    const printProfiles = ref<null | IStoreProfileData[]>(null)
    const keywordSearch = ref("")
    const isLoading = ref(false)
    const companyStore = useCompanyStore()
    const filterMainStore = useFilterMainStore()
    const relationData = ref<any | null>(null)
    const relationProps = ref(false)
    let auxModal;




    // Functions
    async function handleGetProfiles() {
      isLoading.value = true
      try {
        const result: IStoreProfileResponse = await axios.get(`/api/getProfile`)
        const { data } = result
        queryObject.value.data = data.data
        printProfiles.value = data.data
      } catch (error) {
        if(error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
      } finally {
        loaderStore.close()
        isLoading.value = false
      }
    }

    function handleFilterObjectsByKeyword(): boolean {
      if(!queryObject.value.data)
        return true
      printProfiles.value = queryObject.value.data.filter((item) => {
        for (const key in item) {
          if (key !== 'checked') {
            const propValue = item[key];
            if (typeof propValue === 'string' && propValue.toLowerCase().includes(keywordSearch.value.toLowerCase())) {
              return true;
            }
            if (typeof propValue === 'number' && keywordSearch!.toString() === propValue.toString()) {
              return true;
            }
          }
        }
        return false;
      });
      return false
    }

    function handleModalDelRegister(item: IStoreProfileData) {
      toggleModalDelRegister.value = !toggleModalDelRegister.value
      itemDel.value = item
    }

    async function handleConfirmItemDel() {
      if(itemDel.value) {
        try {
          loaderStore.open()
          const result = await axios.delete(`/api/deleteProfile/${itemDel.value.id}`)
          showTimeAlert(`O registro ${itemDel.value.name} foi excluído com sucesso!`)
          handleGetProfiles()
          itemDel.value = null
        } catch (error) {
          if(error instanceof AxiosError) {
              showTimeAlert(error.response?.data.message, "error")
          } else {
            showTimeAlert("Algo deu errado!", "error")
          }
        } finally {
          loaderStore.close()
        }
      }
    }

    function handleInitFilterModal() {
      if(!companyStore.getId) {
        filterMainStore.hideModal()
        filterMainStore.showModal()
      }
    }

    const openModal = id => {
        const auxElement = document.querySelector(`#${id}`);
        auxModal = new Modal(auxElement);
        auxModal.show();
      };

    function redirectToStoreProducts(data) {
        openModal('myModal')
        relationProps.value = !relationProps.value
        relationData.value = data
    }

    // Life Cycles
    onMounted(async () => (handleGetProfiles(), handleInitFilterModal()))

    watch(() => keywordSearch.value, () => handleFilterObjectsByKeyword())

    watch(() => companyStore.id, () => handleGetProfiles(), {deep: true})

    return {
      router,
      keywordSearch,
      printProfiles,
      isLoading,
      queryObject,
      handleModalDelRegister,
      toggleModalDelRegister,
      itemDel,
      handleConfirmItemDel,
      relationProps,
      relationData,
      redirectToStoreProducts

    }
  }
})
